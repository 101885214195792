<template>
    <section>
        <h1>Cargando campaña...</h1>
    </section>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    mounted() {
        const idCampaign = this.$route.params.slugCampaign
        if (idCampaign) {
            this.getInfoCampaign({ idCampaign })
        }
        this.$router.replace({ name: 'Home' })
    },
    methods: {
        ...mapActions('globalState', ['getInfoCampaign'])
    },
}
</script>